<template>
  <div class="container">
    <div class="banner">
      <h1>场景案例</h1>
      <p>
        Scenario cases
      </p>
    </div>
    <div class="content">
      <div class="car-oem">
        <div class="car-oem-left">
          <img src="../assets/image/avatar.jpg" style="width: 124px;height: 124px;background: #D8D8D8;border: 2px solid #FFFFFF; border-radius: 50%; margin-top: 68px;" />
          <p style="height: 24px;font-size: 24px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 24px; margin-top: 44px; margin-bottom: 0;">功能安全经理</p>
          <p style="margin: 58px 68px 0 68px;height: 56px;font-size: 20px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 28px;">“负责一款产品的功能安全开发及认证，需要找到一款管理工具来支持”</p>
          <p style="margin-top: 428px;height: 50px;font-size: 36px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;line-height: 50px; margin-bottom: 0;">汽车OEM</p>
        </div>
        <div class="car-oem-right">
          <div style="margin: 43px 0 37px 0;display: flex;display: -webkit-flex;flex-direction: row;justify-content: flex-start;align-items: center;">
            <div style="width: 4px;height: 24px;background: #4C84FF;"></div>
            <p style="margin: 0 0 0 13px;height: 24px;font-size: 24px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;line-height: 24px;">解决方案</p>
          </div>
          <img style="height: 382px;" src="../assets/image/scenarioCase/doc.png" alt="">
          <img style="height: 332px; margin-top: 52px;" src="../assets/image/scenarioCase/safety-plan.png" alt="">
        </div>
      </div>
      <div class="car-tier1">
        <div class="car-tier1-left">
          <img src="../assets/image/avatar1.jpg" style="width: 124px;height: 124px;background: #D8D8D8;border: 2px solid #FFFFFF; border-radius: 50%; margin-top: 68px;" />
          <p style="height: 24px;font-size: 24px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 24px; margin-top: 44px; margin-bottom: 0;">BMS功能安全工程师</p>
          <p style="margin: 58px 68px 0 68px;height: 56px;font-size: 20px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 28px;">“负责的BMS非常复杂，开展FMEA和FTA利用EXCEL很不方便，总是有遗漏或者考虑不全”</p>
          <p style="margin-top: 428px;height: 50px;font-size: 36px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;line-height: 50px; margin-bottom: 0;">汽车Tier1</p>
        </div>
        <div class="car-tier1-right">
          <div style="margin: 43px 0 37px 0;display: flex;display: -webkit-flex;flex-direction: row;justify-content: flex-start;align-items: center;">
            <div style="width: 4px;height: 24px;background: #4C84FF;"></div>
            <p style="margin: 0 0 0 13px;height: 24px;font-size: 24px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;line-height: 24px;">解决方案</p>
          </div>
          <img style="height: 384px;" src="../assets/image/scenarioCase/tier1-1.png" alt="">
          <img style="height: 348px; margin-top: 33px;" src="../assets/image/scenarioCase/tier1-2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScenarioCase",
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
  .banner {
    height: 450px;
    background-image: url("../assets/image/scenarioCase/banner.png");
    background-size: 100% 450px;
    background-repeat: no-repeat;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
      height: 90px;
      font-size: 64px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 90px;
      margin-bottom: 0;
    }

    & p {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      margin-top: 10px;
    }
  }

  .content {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
  }

  .car-oem {
    width: 1440px;
    height: 920px;
    background: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .car-oem-left {
    height: 100%;
    width: 450px;
    background-image: url("../assets/image/scenarioCase/car-oem.png");
    background-size: 450px 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .car-oem-right {
    height: 100%;
    width: 423px;
    padding-left: 47px;
  }

  .car-tier1 {
    width: 1440px;
    height: 920px;
    background: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 100px;
  }

  .car-tier1-left {
    height: 100%;
    width: 450px;
    background-image: url("../assets/image/scenarioCase/car-tier1.png");
    background-size: 450px 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .car-tier1-right {
    height: 100%;
    width: 423px;
    padding-left: 47px;
  }
</style>
